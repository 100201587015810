




































































import { debounceProcess } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataCalendar } from "@/models/interface/settings.interface";
import { cashManagementServices } from "@/services/cashmanagement.service";
import { settingsServices } from "@/services/settings.service";
import { Component, Vue } from "vue-property-decorator";
import MNotificationVue from "@/mixins/MNotification.vue";

interface LoadingComponent {
  loadingClose: boolean;
  loadingPeriodCm: boolean;
}

@Component({
  mixins: [MNotificationVue],
})
export default class ClosePeriod extends Vue {
  form = this.$form.createForm(this, { name: "purchasingClosePeriod" });
  page = 1;
  limit = 10;
  params: RequestQueryParamsModel = {};
  totalData = 0;
  loading: LoadingComponent = {
    loadingClose: false,
    loadingPeriodCm: false,
  };
  dataPeriodCm: DataCalendar[] = [];

  formRules = {
    periodCm: {
      label: "lbl_period_cm",
      name: "periodCm",
      placeholder: "lbl_period_cm_placeholder",
      decorator: [
        "periodCm",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: [
        "status",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  handleChangePeriod(value) {
    let closePeriod = false;
    this.dataPeriodCm.forEach(dataForeach => {
      if (dataForeach.month === value) {
        dataForeach.calendarClosePeriods.forEach(dataForeachClose => {
          if (dataForeachClose.modulName === "CM") {
            closePeriod = dataForeachClose.closePeriod;
          }
        });
      }
    });
    this.form.setFieldsValue({
      status: !closePeriod ? "Open" : "Closed",
    });
  }

  handleBack() {
    this.$router.push("/dashboard");
  }

  onSubmit() {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        period: res.periodCm,
      };
      this.loading.loadingClose = true;
      cashManagementServices
        .cashManagementClosePeriod("", params)
        .then(() => {
          this.showNotifSuccess("notif_close_period_success");
          this.getPeriodCm(res.periodCm);
          this.form.setFieldsValue({
            status: "Closed",
          });
        })
        .finally(() => (this.loading.loadingClose = false));
    });
  }

  getPeriodCm(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      modul: "CM",
    };
    if (valueSearch) params.search = `month~*${valueSearch}*`;
    this.loading.loadingPeriodCm = true;
    settingsServices
      .listOfCalendar(params, "")
      .then(response => {
        this.dataPeriodCm = response.data.filter(
          dataFilter => !dataFilter.month?.includes("Adj")
        );
      })
      .finally(() => (this.loading.loadingPeriodCm = false));
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  created() {
    this.getPeriodCm = debounceProcess(this.getPeriodCm, 400);
    this.getPeriodCm("");
  }

  mounted() {
    this.form.setFieldsValue({
      status: "Open",
    });
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
